<template>
  <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
</template>

<script>
import SmReport from '@/components/views/SmReport.vue';
export default {
  name: 'CallDayStatisticsReport',

  components: {
    SmReport,
  },

  data() {
    return {
      reportName: 'CallDayStatisticsReport',
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Отчет по статистике звонков за период',
        },
      ],
    };
  },
};
</script>
